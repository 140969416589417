import { Injectable } from "@angular/core";
import { HttpService } from ".";
import { Observable } from "rxjs";
import { ISAPMaterialModel, ISAPMaterialProduct, ISapProfitCenterHierarchyModel, SapProfitCenterFilterType } from "libs/models";
import { environment } from "libs/environment";


@Injectable()
export class ChemicalSearchService {
    constructor(
        private httpService: HttpService
    ) {
    }

    getSapMaterialDescriptionByKeyword(keyword: string): Observable<ISAPMaterialProductModel[]> {
        const key = encodeURI(keyword);
        return this.httpService.Get(`${environment.baseUrl}/api/chemical/filter/sap-material?key=${key}`);
    }

    getGroups(): Observable<ISAPMaterialProduct[]> {
        return this.httpService.Get(`${environment.baseUrl}/api/chemical/filter/groups`);
    }

    getFamilies(groupCode: string): Observable<ISAPMaterialProduct[]> {
        return this.httpService.Get(`${environment.baseUrl}/api/chemical/filter/families?groupcode=${groupCode}`);
    }

    getProducts(familyCode: string): Observable<ISAPMaterialProduct[]> {
        return this.httpService.Get(`${environment.baseUrl}/api/chemical/filter/products?familycode=${familyCode}`);
    }

    getProfitCenterHierarchy(type: SapProfitCenterFilterType, categoryCode: string): Observable<ISapProfitCenterHierarchyModel[]> {
        const url: string = categoryCode == null ? `${environment.baseUrl}/api/chemical/filter/pchierarchy?type=${type}`
        : `${environment.baseUrl}/api/chemical/filter/pchierarchy?type=${type}&categorycode=${categoryCode}`;

        return this.httpService.Get(url);
    }
}

export class ISAPMaterialProductModel implements ISAPMaterialModel {
    materialName: string;
    materialNumber: string;
    groupCode: string;
    familyCode: string;
    productCode: string;
}
